.header {
    z-index: 2;
    position: fixed;
    color: $header-text-color;
    display: flex;
    width: calc(100% - 20px);
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    background-color:transparent;

    /* the logo */
    .logo-container {
        img{
            height: 40px;
            margin-top:5px;
        }
    }
    .header-menu-container {
        float: right;
        .menu-list {
            list-style: none;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-right:20px;
            .menu-item {
                margin: 0px 10px;
                cursor: pointer;
            }
            .menu-item:hover {
                color: $header-text-color-hover;
            }
        }
    }
}
