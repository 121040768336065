/*
$core-brand-background-color: #2e2e2e;
$core-brand-text-color: #ebccc2;
$header-background-color:$core-brand-background-color;
$header-text-color: #867772;
$header-text-color-hover: #695b57;
*/
/***** Libaray CSS override for ScrollUpButton *****
************************************************/
.AnyClassForContainer {
  position: fixed;
  right: -100px;
  bottom: 20px;
  -webkit-transition: right 0.5s;
  transition: right 0.5s;
  cursor: pointer;
  background-color: #76b900;
  color: white;
  font-size: 15px;
  padding: 8px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  outline: none; }

.AnyClassForTransition {
  right: 20px;
  z-index: 2; }

/***** Library CSS Override for Fullpage.js Dot Navigation****
**************************************************************/
#fp-nav ul li,
.fp-slidesNav ul li {
  margin-bottom: 40px !important;
  visibility: inherit;
  opacity: 1 !important; }

#fp-nav ul li::after {
  content: "";
  position: absolute;
  width: 1px;
  height: 50px;
  background-color: white;
  display: block;
  left: 7px;
  top: 12px;
  opacity: 0.1; }

#fp-nav ul li:last-child::after {
  display: none; }

#fp-nav ul li .fp-tooltip {
  width: auto !important;
  opacity: 1 !important;
  color: black !important;
  cursor: cursor !important;
  margin-left: 5px;
  text-transform: uppercase;
  font-size: 11px;
  line-height: 1.5; }

#fp-nav.fp-show-active a.active + .fp-tooltip {
  color: #1E90FF !important; }

#fp-nav ul li a span,
.fp-slidesNav ul li a span {
  width: 10px !important;
  height: 10px !important;
  margin: -4px 0 0 -4px !important;
  background: transparent;
  box-sizing: border-box;
  box-shadow: inset 0 0 0 2px white;
  overflow: hidden; }
  #fp-nav ul li a span::after,
  .fp-slidesNav ul li a span::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 0;
    border-radius: 50%;
    left: 0;
    bottom: 0;
    background-color: #1E90FF;
    box-shadow: 0 0 1px #1E90FF;
    transition: height 0.3s; }

#fp-nav ul li a.active span,
.fp-slidesNav ul li a.active span,
#fp-nav ul li:hover a.active span,
.fp-slidesNav ul li:hover a.active span {
  width: 16px;
  height: 16px;
  margin: -8px 0 0 -8px;
  background: transparent;
  box-sizing: border-box;
  box-shadow: inset 0 0 0 2px white; }
  #fp-nav ul li a.active span::after,
  .fp-slidesNav ul li a.active span::after,
  #fp-nav ul li:hover a.active span::after,
  .fp-slidesNav ul li:hover a.active span::after {
    height: 100%; }

html,
body {
  background: #40485A;
  color: black; }

.clearfix:before,
.clearfix:after {
  content: "";
  display: table; }

.section {
  /* offset for first section */
  /* center headline */
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }
  .section .first-section {
    margin-top: 77px; }
    .section .first-section .socialMedia-connect .socialMedia-heading {
      margin-top: 100px;
      margin-bottom: 0px;
      font-size: 22px; }
  .section .centerblock {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center; }
    .section .centerblock .floatParagraph {
      width: 60vw; }
      .section .centerblock .floatParagraph .document-body {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: 3rem;
        height: 60vh; }
        .section .centerblock .floatParagraph .document-body .document-header {
          font-family: "ShareTech";
          font-size: 2.5rem; }
        .section .centerblock .floatParagraph .document-body .document-content {
          flex-direction: column;
          justify-content: flex-end;
          font-size: 1rem;
          line-height: 1.5; }
  .section .thirdExtra {
    height: 133vh; }
  .section .quarterExtra {
    height: 125vh; }
  .section .halfExtra {
    height: 150vh; }
  .section .section-content-image img {
    width: 48vw;
    height: 100vh;
    background-size: cover !important;
    float: right;
    background-repeat: no-repeat;
    transition: all 0.5s ease;
    background-position: center center; }
  .section .section-content-image.full img {
    width: 100vw;
    height: 90vh;
    margin-bottom: 100px; }
  .section .section-content-image.left {
    float: right; }
  .section .section-content-image.right {
    float: left; }
  .section .section-content-text {
    float: right;
    width: 52vw;
    height: 100vh;
    vertical-align: top;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #40485A; }
    .section .section-content-text.right {
      float: right; }
    .section .section-content-text.left {
      float: left; }
    .section .section-content-text .responsive-section-content-image {
      display: none; }
    .section .section-content-text .document-body {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding: 3rem;
      height: 60vh; }
      .section .section-content-text .document-body .document-header {
        font-family: "ShareTech";
        font-size: 2.5rem; }
      .section .section-content-text .document-body .document-content {
        flex-direction: column;
        justify-content: flex-end;
        font-size: 1rem;
        line-height: 1.5; }

.header {
  z-index: 2;
  position: fixed;
  color: black;
  display: flex;
  width: calc(100% - 20px);
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: transparent;
  /* the logo */ }
  .header .logo-container img {
    height: 40px;
    margin-top: 5px; }
  .header .header-menu-container {
    float: right; }
    .header .header-menu-container .menu-list {
      list-style: none;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-right: 20px; }
      .header .header-menu-container .menu-list .menu-item {
        margin: 0px 10px;
        cursor: pointer; }
      .header .header-menu-container .menu-list .menu-item:hover {
        color: gray; }

.footer {
  clear: both;
  position: relative;
  height: 40px;
  font-size: 12px;
  margin-top: -40px; }
  .footer pre {
    font-family: "Noto Sans Regular";
    text-align: center; }

.slide-up-fade-in {
  animation: slide-up-fade-in ease 1s;
  animation-iteration-count: 1;
  transform-origin: 50% 50%;
  animation-fill-mode: forwards;
  /*when the spec is finished*/
  -webkit-animation: slide-up-fade-in ease 1s;
  -webkit-animation-iteration-count: 1;
  -webkit-transform-origin: 50% 50%;
  -webkit-animation-fill-mode: forwards;
  /*Chrome 16+, Safari 4+*/
  -moz-animation: slide-up-fade-in ease 1s;
  -moz-animation-iteration-count: 1;
  -moz-transform-origin: 50% 50%;
  -moz-animation-fill-mode: forwards;
  /*FF 5+*/
  -o-animation: slide-up-fade-in ease 1s;
  -o-animation-iteration-count: 1;
  -o-transform-origin: 50% 50%;
  -o-animation-fill-mode: forwards;
  /*Not implemented yet*/
  -ms-animation: slide-up-fade-in ease 1s;
  -ms-animation-iteration-count: 1;
  -ms-transform-origin: 50% 50%;
  -ms-animation-fill-mode: forwards;
  /*IE 10+*/
  opacity: 0;
  opacity: 1\9; }

@keyframes slide-up-fade-in {
  0% {
    opacity: 0;
    transform: translate(0px, 40px); }
  100% {
    opacity: 1;
    transform: translate(0px, 0px); } }

@-moz-keyframes slide-up-fade-in {
  0% {
    opacity: 0;
    -moz-transform: translate(0px, 40px); }
  100% {
    opacity: 1;
    -moz-transform: translate(0px, 0px); } }

@-webkit-keyframes slide-up-fade-in {
  0% {
    opacity: 0;
    -webkit-transform: translate(0px, 40px); }
  100% {
    opacity: 1;
    -webkit-transform: translate(0px, 0px); } }

@-o-keyframes slide-up-fade-in {
  0% {
    opacity: 0;
    -o-transform: translate(0px, 40px); }
  100% {
    opacity: 1;
    -o-transform: translate(0px, 0px); } }

@-ms-keyframes slide-up-fade-in {
  0% {
    opacity: 0;
    -ms-transform: translate(0px, 40px); }
  100% {
    opacity: 1;
    -ms-transform: translate(0px, 0px); } }

.dc-page {
  background-color: #f7f7f7; }
  .dc-page .container {
    margin-left: 0px !important;
    margin-right: 0px !important; }

.search-page {
  padding: 80px 30px 0px 30px;
  min-height: 100%; }
  .search-page .access-column {
    display: flex;
    justify-content: flex-start;
    align-items: center; }
    .search-page .access-column .accessImg img {
      width: 16px;
      height: 16px;
      margin-right: 3px; }

.dcdp-right-menu-section-wrapper {
  margin-bottom: 40px; }

.dcdp-right-menu-section-wrapper:last-of-type {
  margin-bottom: 0px; }

.dataset-format-wrapper .item {
  align-items: center; }

.dataset-format-wrapper .dataset-logo {
  width: 30px !important;
  height: 30px !important; }

.dataset-format-wrapper .dataset-name {
  font-size: 13px !important;
  flex-grow: 1; }

.dataset-format-wrapper .dataset-reqaccess {
  color: #4183c4;
  cursor: pointer; }

.detail-page {
  background-color: #f7f7f7;
  padding: 80px 50px 0px 50px;
  min-height: 100%; }

.dcdp-btn-group {
  display: flex;
  flex-direction: column; }
  .dcdp-btn-group button {
    margin: 5px 0px !important; }

.dcdp-overview-section .viewmorebtn {
  margin-top: 10px;
  width: 20px;
  height: 20px; }

.dcdp-overview-section .overviewCard {
  height: 100px; }

.dcdp-overview-section .overviewCard-2 {
  height: 215px; }

.dcdp-overview-section .menuWithoutBorders .overviewcard-header {
  font-size: 15px;
  padding: 0px 0px 10px 0px;
  font-weight: 600; }

.dcdp-overview-section .menuWithoutBorders .overviewcard-body {
  font-size: 14px;
  padding: 10px 0px 0px 0px; }
  .dcdp-overview-section .menuWithoutBorders .overviewcard-body .overview-access-card {
    display: flex;
    justify-content: center;
    align-items: center; }
    .dcdp-overview-section .menuWithoutBorders .overviewcard-body .overview-access-card .accessImg img {
      width: 16px;
      height: 16px;
      margin-right: 3px; }
    .dcdp-overview-section .menuWithoutBorders .overviewcard-body .overview-access-card img {
      width: 16px;
      height: 16px;
      margin-left: 3px;
      cursor: pointer; }

.dcdp-lineage-section .lineage-clickthrough-list {
  text-align: left; }

.dcdp-lineage-section .lineage-clickthrough {
  cursor: pointer;
  color: #4183c4; }

.dcdp-lineage-section img.lineageIcon {
  height: 40px !important;
  width: 40px !important; }

.dcdp-description-section {
  height: 280px; }
  .dcdp-description-section .dcdp-description-section-header {
    display: flex;
    justify-content: space-between; }
    .dcdp-description-section .dcdp-description-section-header .dcdp-description-section-menu .alertLabelColor {
      cursor: pointer;
      background: #f48439;
      color: white; }

.dcdp-dictionary-section .dcdp-dictionary-body {
  word-wrap: break-word; }
  .dcdp-dictionary-section .dcdp-dictionary-body .dcdp-dictionary-column-name {
    font-weight: bold; }
  .dcdp-dictionary-section .dcdp-dictionary-body .dictionaryTableNoResultMessage {
    text-align: center;
    font-size: 15px;
    font-weight: bold; }

.dcdp-version-section .summary {
  font-weight: normal !important; }
  .dcdp-version-section .summary .version-author {
    font-weight: bold; }

.ui.label {
  margin: 2px;
  color: white;
  font-size: 12px;
  font-weight: normal;
  margin: 0.14285714em;
  padding: 3px 6px; }

.notfoundPage {
  height: 100vh;
  width: 100%; }

.serviceErrorPage {
  height: 100vh;
  width: 100%; }
  .serviceErrorPage .container {
    margin-top: 80px; }
  .serviceErrorPage .outageImage {
    display: flex;
    justify-content: center; }
    .serviceErrorPage .outageImage img {
      width: 200px;
      height: 290px; }
  .serviceErrorPage .text--link {
    color: #4183c4;
    cursor: pointer; }

/*
@media all and (max-width: 1199px) {
    @import "./breakpoints/933to1199.scss";
}
@media all and (max-width: 722px) {
    @import "./breakpoints/576to722.scss";
}
@media all and (max-width: 575px) {
    @import "./breakpoints/575below.scss";
}


*/
@media screen and (max-width: 932px) {
  .section .clearfix:not(.first-section):not(.last-section) {
    display: flex;
    justify-content: space-between;
    flex-direction: column; }
    .section .clearfix:not(.first-section):not(.last-section) .section-content-image.left img, .section .clearfix:not(.first-section):not(.last-section) .section-content-image.right img {
      float: none;
      width: 100vw; }
    .section .clearfix:not(.first-section):not(.last-section) .section-content-text.left, .section .clearfix:not(.first-section):not(.last-section) .section-content-text.right {
      float: none;
      width: 100vw; }
  .section .websiteSlogan {
    padding: 0px 4rem; }
  .section .socialMedia-connect .socialMedia-heading {
    margin-top: 50px;
    margin-bottom: 0px;
    font-size: 22px; }
  .section .last-section {
    width: 100vw;
    position: absolute; }
  .contact-section {
    display: flex;
    flex-direction: column !important;
    justify-content: space-between;
    width: 100vw; }
  .sec-intro .intro-group .websiteTitle {
    width: 90vw !important; } }

.cyan-cp-text {
  color: #26dafd;
  bottom: 0;
  display: block;
  opacity: 1;
  overflow: hidden;
  box-sizing: border-box;
  transition: all 250ms ease-out;
  line-height: 1.5;
  font-family: "Titillium Web", "sans-serif"; }

@font-face {
  font-family: "Segoe UI";
  src: url("../fonts/SegoeUI/SegoeUI.eot") format("eot"), url("../fonts/SegoeUI/SegoeUI.woff2") format("woff2"), url("../fonts/SegoeUI/SegoeUI.woff") format("woff"), url("../fonts/SegoeUI/SegoeUI.ttf") format("truetype"), url("../fonts/SegoeUI/SegoeUI.svg#Segoe UI") format("svg"); }

@font-face {
  font-family: "Segoe UI Light";
  src: url("../fonts/SegoeUILight/SegoeUI-Light.eot") format("eot"), url("../fonts/SegoeUILight/SegoeUI-Light.woff2") format("woff2"), url("../fonts/SegoeUILight/SegoeUI-Light.woff") format("woff"), url("../fonts/SegoeUILight/SegoeUI-Light.ttf") format("truetype"), url("../fonts/SegoeUILight/SegoeUI-Light.svg#SegoeUILight") format("svg"); }

@font-face {
  font-family: "ShareTech";
  src: url("../fonts/ShareTech/ShareTech-Regular.eot") format("eot"), url("../fonts/ShareTech/ShareTech-Regular.woff2") format("woff2"), url("../fonts/ShareTech/ShareTech-Regular.woff") format("woff"), url("../fonts/ShareTech/ShareTech-Regular.ttf") format("truetype"), url("../fonts/ShareTech/ShareTech-Regular.svg#ShareTech") format("svg"); }

@font-face {
  font-family: "Noto Sans Regular";
  src: url("../fonts/Noto_Sans/NotoSans.eot");
  src: url("../fonts/Noto_Sans/NotoSans.eot?#iefix") format("embedded-opentype"), url("../fonts/Noto_Sans/NotoSans.woff2") format("woff2"), url("../fonts/Noto_Sans/NotoSans.woff") format("woff"), url("../fonts/Noto_Sans/NotoSans.ttf") format("truetype"), url("../fonts/Noto_Sans/NotoSans.svg#NotoSans") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Noto Sans Bold";
  src: url("../fonts/Noto_Sans/NotoSans-Bold.eot");
  src: url("../fonts/Noto_Sans/NotoSans-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Noto_Sans/NotoSans-Bold.woff2") format("woff2"), url("../fonts/Noto_Sans/NotoSans-Bold.woff") format("woff"), url("../fonts/Noto_Sans/NotoSans-Bold.ttf") format("truetype"), url("../fonts/Noto_Sans/NotoSans-Bold.svg#NotoSans-Bold") format("svg");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: "Noto Sans Bold Italic";
  src: url("../fonts/Noto_Sans/NotoSans-BoldItalic.eot");
  src: url("../fonts/Noto_Sans/NotoSans-BoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Noto_Sans/NotoSans-BoldItalic.woff2") format("woff2"), url("../fonts/Noto_Sans/NotoSans-BoldItalic.woff") format("woff"), url("../fonts/Noto_Sans/NotoSans-BoldItalic.ttf") format("truetype"), url("../fonts/Noto_Sans/NotoSans-BoldItalic.svg#NotoSans-BoldItalic") format("svg");
  font-weight: bold;
  font-style: italic; }

@font-face {
  font-family: "Noto Sans Italic";
  src: url("../fonts/Noto_Sans/NotoSans-Italic.eot");
  src: url("../fonts/Noto_Sans/NotoSans-Italic.eot?#iefix") format("embedded-opentype"), url("../fonts/Noto_Sans/NotoSans-Italic.woff2") format("woff2"), url("../fonts/Noto_Sans/NotoSans-Italic.woff") format("woff"), url("../fonts/Noto_Sans/NotoSans-Italic.ttf") format("truetype"), url("../fonts/Noto_Sans/NotoSans-Italic.svg#NotoSans-Italic") format("svg");
  font-weight: normal;
  font-style: italic; }

/* latin-ext */
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  src: local("Titillium Web Regular"), local("TitilliumWeb-Regular"), url(https://fonts.gstatic.com/s/titilliumweb/v8/NaPecZTIAOhVxoMyOr9n_E7fdM3mDaZRbryhsA.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  src: local("Titillium Web Regular"), local("TitilliumWeb-Regular"), url(https://fonts.gstatic.com/s/titilliumweb/v8/NaPecZTIAOhVxoMyOr9n_E7fdMPmDaZRbrw.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* latin-ext */
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 600;
  src: local("Titillium Web SemiBold"), local("TitilliumWeb-SemiBold"), url(https://fonts.gstatic.com/s/titilliumweb/v8/NaPDcZTIAOhVxoMyOr9n_E7ffBzCGIVzY5abuWIGxA.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 600;
  src: local("Titillium Web SemiBold"), local("TitilliumWeb-SemiBold"), url(https://fonts.gstatic.com/s/titilliumweb/v8/NaPDcZTIAOhVxoMyOr9n_E7ffBzCGItzY5abuWI.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* latin */
@font-face {
  font-family: 'Electrolize';
  font-style: normal;
  font-weight: 400;
  src: local("Electrolize"), local("Electrolize-Regular"), url(https://fonts.gstatic.com/s/electrolize/v9/cIf5Ma1dtE0zSiGSiED7AXEBuI9PQrfA.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

body,
html {
  font-family: "Noto Sans Regular, Heiti TC,STHeitiTC-Light,STHeitiTC-Medium", sans-serif !important;
  font-weight: normal; }

/* fullpage.js dot navigation tooltip */
#fp-nav ul li .fp-tooltip {
  font-family: "ShareTech", sans-serif !important; }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .search-table-wrapper
.searchtable-mainbody-wrapper
.search-page-table-row
.search-page-table-rowbody
.column.one,
  .search-table-wrapper
.searchtable-mainbody-wrapper
.table-header-row
.row
.column.one {
    -ms-flex: 1 !important;
    -webkit-flex: 1 !important;
    flex: 1 !important;
    /* IE 10 */ }
  .search-table-wrapper
.searchtable-mainbody-wrapper
.search-page-table-row
.search-page-table-rowbody
.column.two,
  .search-table-wrapper
.searchtable-mainbody-wrapper
.table-header-row
.row
.column.two {
    -ms-flex: 2 !important;
    -webkit-flex: 2 !important;
    flex: 2 !important;
    /* IE 10 */ }
  .search-table-wrapper
.searchtable-mainbody-wrapper
.search-page-table-row
.search-page-table-rowbody
.column.three,
  .search-table-wrapper
.searchtable-mainbody-wrapper
.table-header-row
.row
.column.three {
    -ms-flex: 3 !important;
    -webkit-flex: 3 !important;
    flex: 3 !important;
    /* IE 10 */ }
  .search-table-wrapper
.searchtable-mainbody-wrapper
.search-page-table-row
.search-page-table-rowbody
.column.four,
  .search-table-wrapper
.searchtable-mainbody-wrapper
.table-header-row
.row
.column.four {
    -ms-flex: 4 !important;
    -webkit-flex: 4 !important;
    flex: 4 !important;
    /* IE 10 */ }
  .search-table-wrapper
.searchtable-mainbody-wrapper
.search-page-table-row
.search-page-table-rowbody
.column.five,
  .search-table-wrapper
.searchtable-mainbody-wrapper
.table-header-row
.row
.column.five {
    -ms-flex: 5 !important;
    -webkit-flex: 5 !important;
    flex: 5 !important;
    /* IE 10 */ }
  .search-table-wrapper
.searchtable-mainbody-wrapper
.search-page-table-row
.search-page-table-rowbody
.column.six,
  .search-table-wrapper
.searchtable-mainbody-wrapper
.table-header-row
.row
.column.six {
    -ms-flex: 6 !important;
    -webkit-flex: 6 !important;
    flex: 6 !important;
    /* IE 10 */ }
  .search-table-wrapper
.searchtable-mainbody-wrapper
.search-page-table-row
.search-page-table-rowbody
.column.seven,
  .search-table-wrapper
.searchtable-mainbody-wrapper
.table-header-row
.row
.column.seven {
    -ms-flex: 7 !important;
    -webkit-flex: 7 !important;
    flex: 7 !important;
    /* IE 10 */ }
  .search-table-wrapper
.searchtable-mainbody-wrapper
.search-page-table-row
.search-page-table-rowbody
.column.eight,
  .search-table-wrapper
.searchtable-mainbody-wrapper
.table-header-row
.row
.column.eight {
    -ms-flex: 8 !important;
    -webkit-flex: 8 !important;
    flex: 8 !important;
    /* IE 10 */ }
  .search-table-wrapper
.searchtable-mainbody-wrapper
.search-page-table-row
.search-page-table-rowbody
.column.nine,
  .search-table-wrapper
.searchtable-mainbody-wrapper
.table-header-row
.row
.column.nine {
    -ms-flex: 9 !important;
    -webkit-flex: 9 !important;
    flex: 9 !important;
    /* IE 10 */ }
  .search-table-wrapper
.searchtable-mainbody-wrapper
.search-page-table-row
.search-page-table-rowbody
.column.ten,
  .search-table-wrapper
.searchtable-mainbody-wrapper
.table-header-row
.row
.column.ten {
    -ms-flex: 10 !important;
    -webkit-flex: 10 !important;
    flex: 10 !important;
    /* IE 10 */ }
  .search-table-wrapper
.searchtable-mainbody-wrapper
.search-page-table-row
.search-page-table-rowbody
.column.eleven,
  .search-table-wrapper
.searchtable-mainbody-wrapper
.table-header-row
.row
.column.eleven {
    -ms-flex: 11 !important;
    -webkit-flex: 11 !important;
    flex: 11 !important;
    /* IE 10 */ }
  .search-table-wrapper
.searchtable-mainbody-wrapper
.search-page-table-row
.search-page-table-rowbody
.column.twelve,
  .search-table-wrapper
.searchtable-mainbody-wrapper
.table-header-row
.row
.column.twelve {
    -ms-flex: 12 !important;
    -webkit-flex: 12 !important;
    flex: 12 !important;
    /* IE 10 */ }
  .search-table-wrapper
.searchtable-mainbody-wrapper
.search-page-table-row
.search-page-table-rowbody
.column.thirteen,
  .search-table-wrapper
.searchtable-mainbody-wrapper
.table-header-row
.row
.column.thirteen {
    -ms-flex: 13 !important;
    -webkit-flex: 13 !important;
    flex: 13 !important;
    /* IE 10 */ }
  .search-table-wrapper
.searchtable-mainbody-wrapper
.search-page-table-row
.search-page-table-rowbody
.column.fourteen,
  .search-table-wrapper
.searchtable-mainbody-wrapper
.table-header-row
.row
.column.fourteen {
    -ms-flex: 14 !important;
    -webkit-flex: 14 !important;
    flex: 14 !important;
    /* IE 10 */ }
  .search-table-wrapper
.searchtable-mainbody-wrapper
.search-page-table-row
.search-page-table-rowbody
.column.fifteen,
  .search-table-wrapper
.searchtable-mainbody-wrapper
.table-header-row
.row
.column.fifteen {
    -ms-flex: 15 !important;
    -webkit-flex: 15 !important;
    flex: 15 !important;
    /* IE 10 */ }
  .search-table-wrapper
.searchtable-mainbody-wrapper
.search-page-table-row
.search-page-table-rowbody
.column.sixteen,
  .search-table-wrapper
.searchtable-mainbody-wrapper
.table-header-row
.row
.column.sixteen {
    -ms-flex: 16 !important;
    -webkit-flex: 16 !important;
    flex: 16 !important;
    /* IE 10 */ } }

.sec-contact {
  background-image: url("../images/new/ContactUs.png"); }
  .sec-contact .contact-group {
    height: 70vh; }

.contact-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 55vw; }
  .contact-section .contact-form form {
    display: flex;
    flex-direction: column;
    height: 300px;
    justify-content: space-between; }
    .contact-section .contact-form form input[type="text"],
    .contact-section .contact-form form input[type="email"],
    .contact-section .contact-form form textarea {
      padding: 5px;
      border: 2px solid #ccc;
      -webkit-border-radius: 5px;
      border-radius: 5px; }
    .contact-section .contact-form form input[type="text"]:focus,
    .contact-section .contact-form form input[type="email"]:focus,
    .contact-section .contact-form form textarea:focus {
      border-color: #333; }
    .contact-section .contact-form form input[type="submit"] {
      padding: 5px 15px;
      background: #ccc;
      border: 0 none;
      cursor: pointer;
      -webkit-border-radius: 5px;
      border-radius: 5px; }
  .contact-section .socialMedia-connect .socialMedia-heading {
    font-family: "ShareTech";
    margin-bottom: 55px;
    font-size: 30px;
    display: flex;
    justify-content: center; }
  .contact-section .socialMedia-connect .socialMedia-content ul {
    display: flex;
    justify-content: center;
    padding-inline-start: 0px;
    margin-block-start: 2em;
    margin-block-end: 2em;
    /*
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                margin: 0;
                padding: 0;
                display: flex;
                */ }
  .contact-section .socialMedia-connect .socialMedia-content ul li {
    list-style: none;
    margin: 0 40px; }
  .contact-section .socialMedia-connect .socialMedia-content ul li .fa {
    font-size: 20px;
    color: #262626;
    line-height: 40px;
    transition: 0.5s; }
  .contact-section .socialMedia-connect .socialMedia-content ul li a {
    position: relative;
    display: block;
    width: 40px;
    height: 40px;
    background-color: #fff;
    text-align: center;
    transform: perspective(100px) rotate(-30deg) skew(25deg) translate(0, 0);
    transition: 0.5s;
    box-shadow: -20px 20px 1px 1px black; }
  .contact-section .socialMedia-connect .socialMedia-content ul li a::before {
    content: "";
    position: absolute;
    top: 10px;
    left: -20px;
    height: 100%;
    width: 20px;
    background: #b1b1b1;
    transition: 0.5s;
    transform: rotate(0deg) skewY(-45deg); }
  .contact-section .socialMedia-connect .socialMedia-content ul li a::after {
    content: "";
    position: absolute;
    top: 40px;
    left: -11px;
    height: 20px;
    width: 100%;
    background: #b1b1b1;
    transition: 0.5s;
    transform: rotate(0deg) skewX(-45deg); }
  .contact-section .socialMedia-connect .socialMedia-content ul li a:hover {
    transform: perspective(1000px) rotate(-30deg) skew(25deg) translate(15px, -15px);
    box-shadow: -40px 40px 40px 20px black; }
  .contact-section .socialMedia-connect .socialMedia-content ul li:hover .fa {
    color: #fff; }
  .contact-section .socialMedia-connect .socialMedia-content ul li:hover .contact-iotex-voting-icon {
    background: #71D1A5; }
  .contact-section .socialMedia-connect .socialMedia-content ul li:hover .contact-iotex-voting-icon:before {
    background: #79dfb1; }
  .contact-section .socialMedia-connect .socialMedia-content ul li:hover .contact-iotex-voting-icon:after {
    background: #69c29a; }
  .contact-section .socialMedia-connect .socialMedia-content ul li:hover .contact-twitter-icon {
    background: #55acee; }
  .contact-section .socialMedia-connect .socialMedia-content ul li:hover .contact-twitter-icon:before {
    background: #4184b7; }
  .contact-section .socialMedia-connect .socialMedia-content ul li:hover .contact-twitter-icon:after {
    background: #4d9fde; }
  .contact-section .socialMedia-connect .socialMedia-content ul li:hover .contact-facebook-icon {
    background: #3b5999; }
  .contact-section .socialMedia-connect .socialMedia-content ul li:hover .contact-facebook-icon:before {
    background: #2e4a86; }
  .contact-section .socialMedia-connect .socialMedia-content ul li:hover .contact-facebook-icon:after {
    background: #4a69ad; }
  .contact-section .socialMedia-connect .socialMedia-content ul li:hover .contact-telegram-icon {
    background: #34ADE1; }
  .contact-section .socialMedia-connect .socialMedia-content ul li:hover .contact-telegram-icon:before {
    background: #2f9cca; }
  .contact-section .socialMedia-connect .socialMedia-content ul li:hover .contact-telegram-icon:after {
    background: #298bb4; }
  .contact-section .socialMedia-connect .socialMedia-content ul li:hover .contact-medium-icon {
    background: #1d1d1d; }
  .contact-section .socialMedia-connect .socialMedia-content ul li:hover .contact-medium-icon:before {
    background: #1f1f1f; }
  .contact-section .socialMedia-connect .socialMedia-content ul li:hover .contact-medium-icon:after {
    background: black; }
  .contact-section .socialMedia-connect .socialMedia-content ul li:hover:nth-child(5) a {
    background: linear-gradient(#400080, transparent), linear-gradient(200deg, #d047d1, #ff0000, #ffff00); }
  .contact-section .socialMedia-connect .socialMedia-content ul li:hover:nth-child(5) a:before {
    background: linear-gradient(#400080, transparent), linear-gradient(200deg, #d047d1, #ff0000, #ffff00); }
  .contact-section .socialMedia-connect .socialMedia-content ul li:hover:nth-child(5) a:after {
    background: linear-gradient(#400080, transparent), linear-gradient(200deg, #d047d1, #ff0000, #ffff00); }
  .contact-section .socialMedia-connect .socialMedia-content ul li .contact-iotex-icon, .contact-section .socialMedia-connect .socialMedia-content ul li .contact-iotex-icon-hover {
    position: absolute;
    margin: auto;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px; }
  .contact-section .socialMedia-connect .socialMedia-content ul li:hover .contact-iotex-icon {
    display: none; }
  .contact-section .socialMedia-connect .socialMedia-content ul li:hover .contact-iotex-icon-hover {
    display: block; }

.sec-intro {
  background-image: url("../images/new/Introduction.png"); }
  .sec-intro .intro-group .websiteTitle {
    height: 500px;
    width: 50vw; }
  .sec-intro .intro-group .websiteSlogan {
    font-family: "ShareTech";
    line-height: 1.5;
    font-size: 2.6rem; }
  .sec-intro .resources-container {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-around; }
    .sec-intro .resources-container .fa-icon-btn {
      display: flex;
      justify-content: center; }
      .sec-intro .resources-container .fa-icon-btn .voterhandbookIcon {
        font-size: 70px;
        color: #758293; }
      .sec-intro .resources-container .fa-icon-btn .voterhandbookIcon:hover {
        cursor: pointer;
        color: #79dfb1; }
  .sec-intro .englishcontent,
  .sec-intro .koreancontent {
    margin: 50px 0px; }
  .sec-intro .mainnet-title-main {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
    text-align: center; }
  .sec-intro .mainnet-title-sub {
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
    text-align: center; }
  .sec-intro .mainnet-twitter-text {
    font-size: 0.9rem;
    margin-top: 1.5rem;
    text-align: center; }
    .sec-intro .mainnet-twitter-text i {
      margin-right: 0.5rem; }
  .sec-intro .socialMedia-connect .socialMedia-heading {
    font-family: "ShareTech";
    margin-bottom: 55px;
    font-size: 30px;
    display: flex;
    justify-content: center; }
  .sec-intro .socialMedia-connect .socialMedia-content ul {
    display: flex;
    justify-content: center;
    padding-inline-start: 0px;
    margin-block-start: 2em;
    margin-block-end: 2em;
    /*
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                margin: 0;
                padding: 0;
                display: flex;
                */ }
  .sec-intro .socialMedia-connect .socialMedia-content ul li {
    list-style: none;
    margin: 0 40px; }
  .sec-intro .socialMedia-connect .socialMedia-content ul li .fa {
    font-size: 20px;
    color: #262626;
    line-height: 40px;
    transition: 0.5s; }
  .sec-intro .socialMedia-connect .socialMedia-content ul li a {
    position: relative;
    display: block;
    width: 40px;
    height: 40px;
    background-color: #758293;
    text-align: center;
    transform: perspective(100px) rotate(-30deg) skew(25deg) translate(0, 0);
    transition: 0.5s;
    box-shadow: -20px 20px 1px 1px black; }
  .sec-intro .socialMedia-connect .socialMedia-content ul li a::before {
    content: "";
    position: absolute;
    top: 10px;
    left: -20px;
    height: 100%;
    width: 20px;
    background: #5a636f;
    transition: 0.5s;
    transform: rotate(0deg) skewY(-45deg); }
  .sec-intro .socialMedia-connect .socialMedia-content ul li a::after {
    content: "";
    position: absolute;
    top: 40px;
    left: -11px;
    height: 20px;
    width: 100%;
    background: #5a636f;
    transition: 0.5s;
    transform: rotate(0deg) skewX(-45deg); }
  .sec-intro .socialMedia-connect .socialMedia-content ul li a:hover {
    transform: perspective(1000px) rotate(-30deg) skew(25deg) translate(15px, -15px);
    box-shadow: -40px 40px 40px 20px black; }
  .sec-intro .socialMedia-connect .socialMedia-content ul li:hover .fa {
    color: #fff; }
  .sec-intro .socialMedia-connect .socialMedia-content ul li:hover .contact-iotex-voting-icon {
    background: #71d1a5; }
  .sec-intro .socialMedia-connect .socialMedia-content ul li:hover .contact-iotex-voting-icon:before {
    background: #79dfb1; }
  .sec-intro .socialMedia-connect .socialMedia-content ul li:hover .contact-iotex-voting-icon:after {
    background: #69c29a; }
  .sec-intro .socialMedia-connect .socialMedia-content ul li:hover .contact-twitter-icon {
    background: #55acee; }
  .sec-intro .socialMedia-connect .socialMedia-content ul li:hover .contact-twitter-icon:before {
    background: #4184b7; }
  .sec-intro .socialMedia-connect .socialMedia-content ul li:hover .contact-twitter-icon:after {
    background: #4d9fde; }
  .sec-intro .socialMedia-connect .socialMedia-content ul li:hover .contact-facebook-icon {
    background: #3b5999; }
  .sec-intro .socialMedia-connect .socialMedia-content ul li:hover .contact-facebook-icon:before {
    background: #2e4a86; }
  .sec-intro .socialMedia-connect .socialMedia-content ul li:hover .contact-facebook-icon:after {
    background: #4a69ad; }
  .sec-intro .socialMedia-connect .socialMedia-content ul li:hover .contact-telegram-icon {
    background: #34ade1; }
  .sec-intro .socialMedia-connect .socialMedia-content ul li:hover .contact-telegram-icon:before {
    background: #2f9cca; }
  .sec-intro .socialMedia-connect .socialMedia-content ul li:hover .contact-telegram-icon:after {
    background: #298bb4; }
  .sec-intro .socialMedia-connect .socialMedia-content ul li:hover .contact-medium-icon {
    background: #1d1d1d; }
  .sec-intro .socialMedia-connect .socialMedia-content ul li:hover .contact-medium-icon:before {
    background: #1f1f1f; }
  .sec-intro .socialMedia-connect .socialMedia-content ul li:hover .contact-medium-icon:after {
    background: black; }
  .sec-intro .socialMedia-connect .socialMedia-content ul li:hover:nth-child(5) a {
    background: linear-gradient(#400080, transparent), linear-gradient(200deg, #d047d1, #ff0000, #ffff00); }
  .sec-intro .socialMedia-connect .socialMedia-content ul li:hover:nth-child(5) a:before {
    background: linear-gradient(#400080, transparent), linear-gradient(200deg, #d047d1, #ff0000, #ffff00); }
  .sec-intro .socialMedia-connect .socialMedia-content ul li:hover:nth-child(5) a:after {
    background: linear-gradient(#400080, transparent), linear-gradient(200deg, #d047d1, #ff0000, #ffff00); }
  .sec-intro .socialMedia-connect .socialMedia-content ul li .contact-iotex-icon,
  .sec-intro .socialMedia-connect .socialMedia-content ul li .contact-iotex-icon-hover {
    position: absolute;
    margin: auto;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px; }
  .sec-intro .socialMedia-connect .socialMedia-content ul li:hover .contact-iotex-icon {
    display: none; }
  .sec-intro .socialMedia-connect .socialMedia-content ul li:hover .contact-iotex-icon-hover {
    display: block; }

.sec-communityplan {
  background-image: url("../images/new/CommunityPlan.png"); }
  .sec-communityplan .communityplan-group {
    height: 70vh; }
    .sec-communityplan .communityplan-group .communityplan-intro-document {
      margin-bottom: 5rem; }

.sec-countdown {
  background-image: url("../images/new/MainnetCountdown.png"); }
  .sec-countdown .countdown-group {
    height: 70vh;
    display: flex;
    justify-content: center; }
    .sec-countdown .countdown-group .resources-container {
      display: flex;
      flex-direction: row;
      justify-content: space-around; }
      .sec-countdown .countdown-group .resources-container .fa-icon-btn {
        display: flex;
        justify-content: center; }
        .sec-countdown .countdown-group .resources-container .fa-icon-btn .voterhandbookIcon {
          font-size: 70px;
          color: #758293; }
        .sec-countdown .countdown-group .resources-container .fa-icon-btn .voterhandbookIcon:hover {
          cursor: pointer;
          color: #79dfb1; }
    .sec-countdown .countdown-group .englishcontent, .sec-countdown .countdown-group .koreancontent {
      margin: 50px 0px; }
    .sec-countdown .countdown-group .mainnet-title-main {
      font-size: 2.5rem;
      margin-bottom: 1.5rem;
      text-align: center; }
    .sec-countdown .countdown-group .mainnet-title-sub {
      font-size: 1.2rem;
      margin-bottom: 1.5rem;
      text-align: center; }
    .sec-countdown .countdown-group .mainnet-twitter-text {
      font-size: 0.9rem;
      margin-top: 1.5rem;
      text-align: center; }
      .sec-countdown .countdown-group .mainnet-twitter-text i {
        margin-right: 0.5rem; }
    .sec-countdown .countdown-group .socialMedia-connect .socialMedia-heading {
      font-family: "ShareTech";
      margin-bottom: 55px;
      font-size: 30px;
      display: flex;
      justify-content: center; }
    .sec-countdown .countdown-group .socialMedia-connect .socialMedia-content ul {
      display: flex;
      justify-content: center;
      padding-inline-start: 0px;
      margin-block-start: 2em;
      margin-block-end: 2em;
      /*
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    margin: 0;
                    padding: 0;
                    display: flex;
                    */ }
    .sec-countdown .countdown-group .socialMedia-connect .socialMedia-content ul li {
      list-style: none;
      margin: 0 40px; }
    .sec-countdown .countdown-group .socialMedia-connect .socialMedia-content ul li .fa {
      font-size: 20px;
      color: #262626;
      line-height: 40px;
      transition: 0.5s; }
    .sec-countdown .countdown-group .socialMedia-connect .socialMedia-content ul li a {
      position: relative;
      display: block;
      width: 40px;
      height: 40px;
      background-color: #758293;
      text-align: center;
      transform: perspective(100px) rotate(-30deg) skew(25deg) translate(0, 0);
      transition: 0.5s;
      box-shadow: -20px 20px 1px 1px black; }
    .sec-countdown .countdown-group .socialMedia-connect .socialMedia-content ul li a::before {
      content: "";
      position: absolute;
      top: 10px;
      left: -20px;
      height: 100%;
      width: 20px;
      background: #5a636f;
      transition: 0.5s;
      transform: rotate(0deg) skewY(-45deg); }
    .sec-countdown .countdown-group .socialMedia-connect .socialMedia-content ul li a::after {
      content: "";
      position: absolute;
      top: 40px;
      left: -11px;
      height: 20px;
      width: 100%;
      background: #5a636f;
      transition: 0.5s;
      transform: rotate(0deg) skewX(-45deg); }
    .sec-countdown .countdown-group .socialMedia-connect .socialMedia-content ul li a:hover {
      transform: perspective(1000px) rotate(-30deg) skew(25deg) translate(15px, -15px);
      box-shadow: -40px 40px 40px 20px black; }
    .sec-countdown .countdown-group .socialMedia-connect .socialMedia-content ul li:hover .fa {
      color: #fff; }
    .sec-countdown .countdown-group .socialMedia-connect .socialMedia-content ul li:hover .contact-iotex-voting-icon {
      background: #71D1A5; }
    .sec-countdown .countdown-group .socialMedia-connect .socialMedia-content ul li:hover .contact-iotex-voting-icon:before {
      background: #79dfb1; }
    .sec-countdown .countdown-group .socialMedia-connect .socialMedia-content ul li:hover .contact-iotex-voting-icon:after {
      background: #69c29a; }
    .sec-countdown .countdown-group .socialMedia-connect .socialMedia-content ul li:hover .contact-twitter-icon {
      background: #55acee; }
    .sec-countdown .countdown-group .socialMedia-connect .socialMedia-content ul li:hover .contact-twitter-icon:before {
      background: #4184b7; }
    .sec-countdown .countdown-group .socialMedia-connect .socialMedia-content ul li:hover .contact-twitter-icon:after {
      background: #4d9fde; }
    .sec-countdown .countdown-group .socialMedia-connect .socialMedia-content ul li:hover .contact-facebook-icon {
      background: #3b5999; }
    .sec-countdown .countdown-group .socialMedia-connect .socialMedia-content ul li:hover .contact-facebook-icon:before {
      background: #2e4a86; }
    .sec-countdown .countdown-group .socialMedia-connect .socialMedia-content ul li:hover .contact-facebook-icon:after {
      background: #4a69ad; }
    .sec-countdown .countdown-group .socialMedia-connect .socialMedia-content ul li:hover .contact-telegram-icon {
      background: #34ADE1; }
    .sec-countdown .countdown-group .socialMedia-connect .socialMedia-content ul li:hover .contact-telegram-icon:before {
      background: #2f9cca; }
    .sec-countdown .countdown-group .socialMedia-connect .socialMedia-content ul li:hover .contact-telegram-icon:after {
      background: #298bb4; }
    .sec-countdown .countdown-group .socialMedia-connect .socialMedia-content ul li:hover .contact-medium-icon {
      background: #1d1d1d; }
    .sec-countdown .countdown-group .socialMedia-connect .socialMedia-content ul li:hover .contact-medium-icon:before {
      background: #1f1f1f; }
    .sec-countdown .countdown-group .socialMedia-connect .socialMedia-content ul li:hover .contact-medium-icon:after {
      background: black; }
    .sec-countdown .countdown-group .socialMedia-connect .socialMedia-content ul li:hover:nth-child(5) a {
      background: linear-gradient(#400080, transparent), linear-gradient(200deg, #d047d1, #ff0000, #ffff00); }
    .sec-countdown .countdown-group .socialMedia-connect .socialMedia-content ul li:hover:nth-child(5) a:before {
      background: linear-gradient(#400080, transparent), linear-gradient(200deg, #d047d1, #ff0000, #ffff00); }
    .sec-countdown .countdown-group .socialMedia-connect .socialMedia-content ul li:hover:nth-child(5) a:after {
      background: linear-gradient(#400080, transparent), linear-gradient(200deg, #d047d1, #ff0000, #ffff00); }
    .sec-countdown .countdown-group .socialMedia-connect .socialMedia-content ul li .contact-iotex-icon, .sec-countdown .countdown-group .socialMedia-connect .socialMedia-content ul li .contact-iotex-icon-hover {
      position: absolute;
      margin: auto;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px; }
    .sec-countdown .countdown-group .socialMedia-connect .socialMedia-content ul li:hover .contact-iotex-icon {
      display: none; }
    .sec-countdown .countdown-group .socialMedia-connect .socialMedia-content ul li:hover .contact-iotex-icon-hover {
      display: block; }

/*** 

Countdown Library 

****/
.flipdown.flipdown__theme-dark .rotor-group-heading:before {
  color: black; }

.flipdown.flipdown__theme-dark
.rotor-group:nth-child(n + 2):nth-child(-n + 3):before,
.flipdown.flipdown__theme-dark
.rotor-group:nth-child(n + 2):nth-child(-n + 3):after {
  background-color: black; }

.flipdown.flipdown__theme-dark {
  font-family: sans-serif;
  font-weight: bold; }

.flipdown.flipdown__theme-dark .rotor,
.flipdown.flipdown__theme-dark .rotor-top,
.flipdown.flipdown__theme-dark .rotor-leaf-front {
  color: #fff;
  background-color: #151515; }

.flipdown.flipdown__theme-dark .rotor-bottom,
.flipdown.flipdown__theme-dark .rotor-leaf-rear {
  color: #efefef;
  background-color: #202020; }

.flipdown.flipdown__theme-dark .rotor:after {
  border-top: solid 1px #151515; }

.flipdown.flipdown__theme-light {
  font-family: sans-serif;
  font-weight: bold; }

.flipdown.flipdown__theme-light .rotor-group-heading:before {
  color: #eee; }

.flipdown.flipdown__theme-light
.rotor-group:nth-child(n + 2):nth-child(-n + 3):before,
.flipdown.flipdown__theme-light
.rotor-group:nth-child(n + 2):nth-child(-n + 3):after {
  background-color: #ddd; }

.flipdown.flipdown__theme-light .rotor,
.flipdown.flipdown__theme-light .rotor-top,
.flipdown.flipdown__theme-light .rotor-leaf-front {
  color: #222;
  background-color: #ddd; }

.flipdown.flipdown__theme-light .rotor-bottom,
.flipdown.flipdown__theme-light .rotor-leaf-rear {
  color: #333;
  background-color: #eee; }

.flipdown.flipdown__theme-light .rotor:after {
  border-top: solid 1px #222; }

.flipdown {
  overflow: visible;
  width: 510px;
  height: 110px; }

.flipdown .rotor-group {
  position: relative;
  float: left;
  padding-right: 30px; }

.flipdown .rotor-group:last-child {
  padding-right: 0; }

.flipdown .rotor-group-heading:before {
  display: block;
  height: 30px;
  line-height: 30px;
  text-align: center; }

.flipdown .rotor-group:nth-child(1) .rotor-group-heading:before {
  content: "Days"; }

.flipdown .rotor-group:nth-child(2) .rotor-group-heading:before {
  content: "Hours"; }

.flipdown .rotor-group:nth-child(3) .rotor-group-heading:before {
  content: "Minutes"; }

.flipdown .rotor-group:nth-child(4) .rotor-group-heading:before {
  content: "Seconds"; }

.flipdown .rotor-group:nth-child(n + 2):nth-child(-n + 3):before {
  content: "";
  position: absolute;
  bottom: 20px;
  left: 115px;
  width: 10px;
  height: 10px;
  border-radius: 50%; }

.flipdown .rotor-group:nth-child(n + 2):nth-child(-n + 3):after {
  content: "";
  position: absolute;
  bottom: 50px;
  left: 115px;
  width: 10px;
  height: 10px;
  border-radius: 50%; }

.flipdown .rotor {
  position: relative;
  float: left;
  width: 50px;
  height: 80px;
  margin: 0 5px 0 0;
  border-radius: 4px;
  font-size: 4rem;
  text-align: center;
  perspective: 200px; }

.flipdown .rotor:last-child {
  margin-right: 0; }

.flipdown .rotor-top,
.flipdown .rotor-bottom {
  overflow: hidden;
  position: absolute;
  width: 50px;
  height: 40px; }

.flipdown .rotor-leaf {
  z-index: 1;
  position: absolute;
  width: 50px;
  height: 80px;
  transform-style: preserve-3d;
  transition: transform 0s; }

.flipdown .rotor-leaf.flipped {
  transform: rotateX(-180deg);
  transition: all 0.5s ease-in-out; }

.flipdown .rotor-leaf-front,
.flipdown .rotor-leaf-rear {
  overflow: hidden;
  position: absolute;
  width: 50px;
  height: 40px;
  margin: 0;
  transform: rotateX(0);
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden; }

.flipdown .rotor-leaf-front {
  line-height: 80px;
  border-radius: 4px 4px 0 0; }

.flipdown .rotor-leaf-rear {
  line-height: 0;
  border-radius: 0 0 4px 4px;
  transform: rotateX(-180deg); }

.flipdown .rotor-top {
  line-height: 80px;
  border-radius: 4px 4px 0 0; }

.flipdown .rotor-bottom {
  bottom: 0;
  line-height: 0;
  border-radius: 0 0 4px 4px; }

.flipdown .rotor:after {
  content: "";
  z-index: 2;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50px;
  height: 40px;
  border-radius: 0 0 4px 4px; }

@media (max-width: 550px) {
  .flipdown {
    width: 312px;
    height: 70px; }
  .flipdown .rotor {
    font-size: 2.2rem;
    margin-right: 3px; }
  .flipdown .rotor,
  .flipdown .rotor-leaf,
  .flipdown .rotor-leaf-front,
  .flipdown .rotor-leaf-rear,
  .flipdown .rotor-top,
  .flipdown .rotor-bottom,
  .flipdown .rotor:after {
    width: 30px; }
  .flipdown .rotor-group {
    padding-right: 20px; }
  .flipdown .rotor-group:last-child {
    padding-right: 0; }
  .flipdown .rotor-group-heading:before {
    font-size: 0.8rem;
    height: 20px;
    line-height: 20px; }
  .flipdown .rotor-group:nth-child(n + 2):nth-child(-n + 3):before,
  .flipdown .rotor-group:nth-child(n + 2):nth-child(-n + 3):after {
    left: 69px; }
  .flipdown .rotor-group:nth-child(n + 2):nth-child(-n + 3):before {
    bottom: 13px;
    height: 8px;
    width: 8px; }
  .flipdown .rotor-group:nth-child(n + 2):nth-child(-n + 3):after {
    bottom: 29px;
    height: 8px;
    width: 8px; }
  .flipdown .rotor-leaf-front,
  .flipdown .rotor-top {
    line-height: 50px; }
  .flipdown .rotor-leaf,
  .flipdown .rotor {
    height: 50px; }
  .flipdown .rotor-leaf-front,
  .flipdown .rotor-leaf-rear,
  .flipdown .rotor-top,
  .flipdown .rotor-bottom,
  .flipdown .rotor:after {
    height: 25px; } }

.sec-about {
  background-image: url("../images/new/AboutUs.png"); }
  .sec-about .about-group {
    height: 70vh; }

.sec-tech {
  background-image: url("../images/new/TechSetup.png"); }
  .sec-tech .techsetup-group {
    height: 70vh; }

.sec-team {
  background-image: url("../images/new/TheTeam.png"); }
  .sec-team .team-group {
    height: 70vh; }

.sec-reward {
  background-image: url("../images/new/TechSetup.png");
  background-position: center;
  background-size: cover; }
  .sec-reward .reward-group {
    height: 70vh; }

.mint-form-primary .mint-form-item {
  margin-bottom: 20px; }

.mint-form-primary .mint-form-item > .ant-form-item-label > label, .mint-form-primary .mint-form-item > .ant-form-item-control > .ant-form-item-extra {
  color: #26dafd !important;
  bottom: 0;
  display: block;
  opacity: 1;
  overflow: hidden;
  box-sizing: border-box;
  transition: all 250ms ease-out;
  line-height: 1.5;
  font-family: "Titillium Web", "sans-serif"; }

.mint-form-primary .form-control-btn-group .ant-form-item-control-input-content {
  display: flex;
  justify-content: space-between; }
