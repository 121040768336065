.footer {
    clear: both;
    position: relative;
    height: 40px;
    font-size:12px;
    margin-top: -40px;
 
    pre{
        font-family: "Noto Sans Regular";
        text-align: center;
    }
}
