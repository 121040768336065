/*
$core-brand-background-color: #2e2e2e;
$core-brand-text-color: #ebccc2;
$header-background-color:$core-brand-background-color;
$header-text-color: #867772;
$header-text-color-hover: #695b57;
*/
$core-brand-background-color: #40485A;
//$core-brand-text-color: #F9E3CB;//#ECC65A;
$core-brand-text-color: black;//#ECC65A;
$header-background-color:#212B3E;
//$header-text-color: #F9E3CB;
//$header-text-color-hover: rgb(189, 171, 153);
$header-text-color:black;
$header-text-color-hover: gray;