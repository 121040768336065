.dcdp-right-menu-section-wrapper {
    margin-bottom: 40px;
}
.dcdp-right-menu-section-wrapper:last-of-type {
    margin-bottom: 0px;
}
// right side menu - dataset format
.dataset-format-wrapper {
    .item {
        align-items: center;
    }
    .dataset-logo {
        width: 30px !important;
        height: 30px !important;
    }
    .dataset-name {
        font-size: 13px !important;
        flex-grow: 1;
    }
    .dataset-reqaccess {
        color: #4183c4;
        cursor: pointer;
    }
}

.detail-page {
    background-color: #f7f7f7;
    padding: 80px 50px 0px 50px;
    min-height: 100%;
}
.dcdp-btn-group {
    display: flex;
    flex-direction: column;
    button {
        margin: 5px 0px !important;
    }
}
//overview
.dcdp-overview-section {
    .viewmorebtn {
        margin-top: 10px;
        width: 20px;
        height: 20px;
    }
    .overviewCard {
        height: 100px;
    }
    .overviewCard-2 {
        height: 215px;
    }
    .menuWithoutBorders {
        .overviewcard-header {
            font-size:15px;
            padding: 0px 0px 10px 0px;
            font-weight: 600;
        }
        .overviewcard-body {
            font-size:14px;
            padding: 10px 0px 0px 0px;
            .overview-access-card {
                display: flex;
                justify-content: center;
                align-items: center;
                .accessText {
                }
                .accessImg {
                    img {
                        width: 16px;
                        height: 16px;
                        margin-right: 3px;
                    }
                }
                img {
                    width: 16px;
                    height: 16px;
                    margin-left: 3px;
                    cursor: pointer;
                }
            }
        }
    }
}
// lineage
.dcdp-lineage-section {
    .lineage-clickthrough-list {
        text-align: left;
    }
    .lineage-clickthrough {
        cursor: pointer;
        color: #4183c4;
    }
    img.lineageIcon {
        height: 40px !important;
        width: 40px !important;
    }
}
// description
.dcdp-description-section {
    height: 280px;
    .dcdp-description-section-header {
        display: flex;
        justify-content: space-between;
        .dcdp-description-section-headertext {
        }
        .dcdp-description-section-menu {
            .alertLabelColor {
                cursor: pointer;
                background: #f48439;
                color: white;
            }
        }
    }
}
// dictionary
.dcdp-dictionary-section {
    .dcdp-dictionary-body {
        word-wrap: break-word;
        .dcdp-dictionary-column-name {
            font-weight: bold;
        }
        .dictionaryTableNoResultMessage {
            text-align: center;
            font-size: 15px;
            font-weight: bold;
        }
    }
}

//version history
.dcdp-version-section {
    .summary {
        font-weight: normal !important;
        .version-author {
            font-weight: bold;
        }
    }
}
.ui.label {
    margin: 2px;
    color: white;
    font-size: 12px;
    font-weight: normal;
    margin: 0.14285714em;
    padding: 3px 6px;
}
