.mint-form-primary{
    .center-div{

    }
    .mint-form-item{
        margin-bottom:20px;
    }
    .mint-form-item > .ant-form-item-label > label, .mint-form-item > .ant-form-item-control > .ant-form-item-extra {
        color: #26dafd !important;
        bottom: 0;
        display: block;
        opacity: 1;
        overflow: hidden;
        box-sizing: border-box;
        transition: all 250ms ease-out;
        line-height: 1.5;
        font-family: "Titillium Web", "sans-serif";
    }
    .form-control-btn-group .ant-form-item-control-input-content{
        display: flex;
        justify-content: space-between;
    }
}