.serviceErrorPage {
    height: 100vh;
    width: 100%;
    .container{
        margin-top:80px;
    }
    .outageImage{
        display: flex;
        justify-content: center;
        img{
            width:200px;
            height:290px;
        }
    }
    .text--link{
        color:#4183c4;
        cursor: pointer;
    }
}