@font-face {
    font-family: "Segoe UI";
    src: url("../fonts/SegoeUI/SegoeUI.eot") format("eot"),
        url("../fonts/SegoeUI/SegoeUI.woff2") format("woff2"),
        url("../fonts/SegoeUI/SegoeUI.woff") format("woff"),
        url("../fonts/SegoeUI/SegoeUI.ttf") format("truetype"),
        url("../fonts/SegoeUI/SegoeUI.svg#Segoe UI") format("svg");
}
@font-face {
    font-family: "Segoe UI Light";
    src: url("../fonts/SegoeUILight/SegoeUI-Light.eot") format("eot"),
        url("../fonts/SegoeUILight/SegoeUI-Light.woff2") format("woff2"),
        url("../fonts/SegoeUILight/SegoeUI-Light.woff") format("woff"),
        url("../fonts/SegoeUILight/SegoeUI-Light.ttf") format("truetype"),
        url("../fonts/SegoeUILight/SegoeUI-Light.svg#SegoeUILight")
            format("svg");
}
@font-face {
    font-family: "ShareTech";
    src: url("../fonts/ShareTech/ShareTech-Regular.eot") format("eot"),
        url("../fonts/ShareTech/ShareTech-Regular.woff2") format("woff2"),
        url("../fonts/ShareTech/ShareTech-Regular.woff") format("woff"),
        url("../fonts/ShareTech/ShareTech-Regular.ttf") format("truetype"),
        url("../fonts/ShareTech/ShareTech-Regular.svg#ShareTech") format("svg");
}
@font-face {
    font-family: "Noto Sans Regular";
    src: url("../fonts/Noto_Sans/NotoSans.eot");
    src: url("../fonts/Noto_Sans/NotoSans.eot?#iefix")
            format("embedded-opentype"),
        url("../fonts/Noto_Sans/NotoSans.woff2") format("woff2"),
        url("../fonts/Noto_Sans/NotoSans.woff") format("woff"),
        url("../fonts/Noto_Sans/NotoSans.ttf") format("truetype"),
        url("../fonts/Noto_Sans/NotoSans.svg#NotoSans") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Noto Sans Bold";
    src: url("../fonts/Noto_Sans/NotoSans-Bold.eot");
    src: url("../fonts/Noto_Sans/NotoSans-Bold.eot?#iefix")
            format("embedded-opentype"),
        url("../fonts/Noto_Sans/NotoSans-Bold.woff2") format("woff2"),
        url("../fonts/Noto_Sans/NotoSans-Bold.woff") format("woff"),
        url("../fonts/Noto_Sans/NotoSans-Bold.ttf") format("truetype"),
        url("../fonts/Noto_Sans/NotoSans-Bold.svg#NotoSans-Bold") format("svg");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Noto Sans Bold Italic";
    src: url("../fonts/Noto_Sans/NotoSans-BoldItalic.eot");
    src: url("../fonts/Noto_Sans/NotoSans-BoldItalic.eot?#iefix")
            format("embedded-opentype"),
        url("../fonts/Noto_Sans/NotoSans-BoldItalic.woff2") format("woff2"),
        url("../fonts/Noto_Sans/NotoSans-BoldItalic.woff") format("woff"),
        url("../fonts/Noto_Sans/NotoSans-BoldItalic.ttf") format("truetype"),
        url("../fonts/Noto_Sans/NotoSans-BoldItalic.svg#NotoSans-BoldItalic")
            format("svg");
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: "Noto Sans Italic";
    src: url("../fonts/Noto_Sans/NotoSans-Italic.eot");
    src: url("../fonts/Noto_Sans/NotoSans-Italic.eot?#iefix")
            format("embedded-opentype"),
        url("../fonts/Noto_Sans/NotoSans-Italic.woff2") format("woff2"),
        url("../fonts/Noto_Sans/NotoSans-Italic.woff") format("woff"),
        url("../fonts/Noto_Sans/NotoSans-Italic.ttf") format("truetype"),
        url("../fonts/Noto_Sans/NotoSans-Italic.svg#NotoSans-Italic")
            format("svg");
    font-weight: normal;
    font-style: italic;
}

/* latin-ext */
@font-face {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 400;
    src: local('Titillium Web Regular'), local('TitilliumWeb-Regular'), url(https://fonts.gstatic.com/s/titilliumweb/v8/NaPecZTIAOhVxoMyOr9n_E7fdM3mDaZRbryhsA.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 400;
    src: local('Titillium Web Regular'), local('TitilliumWeb-Regular'), url(https://fonts.gstatic.com/s/titilliumweb/v8/NaPecZTIAOhVxoMyOr9n_E7fdMPmDaZRbrw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 600;
    src: local('Titillium Web SemiBold'), local('TitilliumWeb-SemiBold'), url(https://fonts.gstatic.com/s/titilliumweb/v8/NaPDcZTIAOhVxoMyOr9n_E7ffBzCGIVzY5abuWIGxA.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 600;
    src: local('Titillium Web SemiBold'), local('TitilliumWeb-SemiBold'), url(https://fonts.gstatic.com/s/titilliumweb/v8/NaPDcZTIAOhVxoMyOr9n_E7ffBzCGItzY5abuWI.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* latin */
@font-face {
    font-family: 'Electrolize';
    font-style: normal;
    font-weight: 400;
    src: local('Electrolize'), local('Electrolize-Regular'), url(https://fonts.gstatic.com/s/electrolize/v9/cIf5Ma1dtE0zSiGSiED7AXEBuI9PQrfA.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

body,
html {
    font-family: "Noto Sans Regular, Heiti TC,STHeitiTC-Light,STHeitiTC-Medium",
        sans-serif !important;
    font-weight: normal;
}

/* fullpage.js dot navigation tooltip */
#fp-nav ul li .fp-tooltip {
    font-family: "ShareTech", sans-serif !important;
}
