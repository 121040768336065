// FIX for IE - asset search page table grid is not aligned,
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .search-table-wrapper
        .searchtable-mainbody-wrapper
        .search-page-table-row
        .search-page-table-rowbody
        .column,
    .search-table-wrapper
        .searchtable-mainbody-wrapper
        .table-header-row
        .row
        .column {
        &.one {
            -ms-flex: 1 !important;
            -webkit-flex: 1 !important;
            flex: 1 !important; /* IE 10 */
        }
        &.two {
            -ms-flex: 2 !important;
            -webkit-flex: 2 !important;
            flex: 2 !important; /* IE 10 */
        }
        &.three {
            -ms-flex: 3 !important;
            -webkit-flex: 3 !important;
            flex: 3 !important; /* IE 10 */
        }
        &.four {
            -ms-flex: 4 !important;
            -webkit-flex: 4 !important;
            flex: 4 !important; /* IE 10 */
        }
        &.five {
            -ms-flex: 5 !important;
            -webkit-flex: 5 !important;
            flex: 5 !important; /* IE 10 */
        }
        &.six {
            -ms-flex: 6 !important;
            -webkit-flex: 6 !important;
            flex: 6 !important; /* IE 10 */
        }
        &.seven {
            -ms-flex: 7 !important;
            -webkit-flex: 7 !important;
            flex: 7 !important; /* IE 10 */
        }
        &.eight {
            -ms-flex: 8 !important;
            -webkit-flex: 8 !important;
            flex: 8 !important; /* IE 10 */
        }
        &.nine {
            -ms-flex: 9 !important;
            -webkit-flex: 9 !important;
            flex: 9 !important; /* IE 10 */
        }
        &.ten {
            -ms-flex: 10 !important;
            -webkit-flex: 10 !important;
            flex: 10 !important; /* IE 10 */
        }
        &.eleven {
            -ms-flex: 11 !important;
            -webkit-flex: 11 !important;
            flex: 11 !important; /* IE 10 */
        }
        &.twelve {
            -ms-flex: 12 !important;
            -webkit-flex: 12 !important;
            flex: 12 !important; /* IE 10 */
        }
        &.thirteen {
            -ms-flex: 13 !important;
            -webkit-flex: 13 !important;
            flex: 13 !important; /* IE 10 */
        }
        &.fourteen {
            -ms-flex: 14 !important;
            -webkit-flex: 14 !important;
            flex: 14 !important; /* IE 10 */
        }
        &.fifteen {
            -ms-flex: 15 !important;
            -webkit-flex: 15 !important;
            flex: 15 !important; /* IE 10 */
        }
        &.sixteen {
            -ms-flex: 16 !important;
            -webkit-flex: 16 !important;
            flex: 16 !important; /* IE 10 */
        }
    }
}
