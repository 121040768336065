.sec-countdown{
    background-image: url('../images/new/MainnetCountdown.png');
    .countdown-group{
        height: 70vh;
        display: flex;
                justify-content: center;
        .resources-container {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            .fa-icon-btn {
                display: flex;
                justify-content: center;
                .voterhandbookIcon {
                    font-size: 70px;
                    color: #758293;
                    //margin: 15px 0px;
                }
                .voterhandbookIcon:hover {
                    cursor: pointer;
                    color: rgb(121, 223, 177);
                }
            }
        }
        .englishcontent,.koreancontent{
            margin: 50px 0px;
        }
        .mainnet-title-main{
            font-size:2.5rem;
            margin-bottom: 1.5rem;
            text-align: center;
        }
        .mainnet-title-sub{
            font-size:1.2rem;
            margin-bottom: 1.5rem;
            text-align: center;
        }
        .mainnet-twitter-text{
            font-size:0.9rem;
            margin-top: 1.5rem;
            text-align: center;
            i{
                margin-right:0.5rem
            }
        }
        .socialMedia-connect {
            .socialMedia-heading{
                font-family: "ShareTech";
                margin-bottom:55px;
                font-size: 30px;
                display: flex;
                justify-content: center;
            }
            .socialMedia-content {
                ul {
                    display: flex;
                    justify-content: center;
                    padding-inline-start: 0px;
                    margin-block-start: 2em;
                    margin-block-end: 2em;
                    /*
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    margin: 0;
                    padding: 0;
                    display: flex;
                    */
                }
                ul li {
                    list-style: none;
                    margin: 0 40px;
                }
                ul li .fa {
                    font-size: 20px;
                    color: #262626;
                    line-height: 40px;
                    transition: 0.5s;
                }
                ul li a {
                    position: relative;
                    display: block;
                    width: 40px;
                    height: 40px;
                    background-color: #758293;
                    text-align: center;
                    transform: perspective(100px) rotate(-30deg) skew(25deg)
                        translate(0, 0);
                    transition: 0.5s;
                    box-shadow: -20px 20px 1px 1px black;
                }
                ul li a::before {
                    content: "";
                    position: absolute;
                    top: 10px;
                    left: -20px;
                    height: 100%;
                    width: 20px;
                    background: #5a636f;
                    transition: 0.5s;
                    transform: rotate(0deg) skewY(-45deg);
                }
                ul li a::after {
                    content: "";
                    position: absolute;
                    top: 40px;
                    left: -11px;
                    height: 20px;
                    width: 100%;
                    background: #5a636f;
                    transition: 0.5s;
                    transform: rotate(0deg) skewX(-45deg);
                }
                ul li a:hover {
                    transform: perspective(1000px) rotate(-30deg) skew(25deg)
                        translate(15px, -15px);
                    box-shadow: -40px 40px 40px 20px black;
                }
                ul li:hover .fa {
                    color: #fff;
                }
        
                ul li:hover .contact-iotex-voting-icon {
                    background: #71D1A5;
                }
        
                ul li:hover .contact-iotex-voting-icon:before {
                    background: rgb(121, 223, 177);
                }
                ul li:hover .contact-iotex-voting-icon:after {
                    background: rgb(105, 194, 154);
                }
                ul li:hover .contact-twitter-icon {
                    background: #55acee;
                }
                ul li:hover .contact-twitter-icon:before {
                    background: #4184b7;
                }
                ul li:hover .contact-twitter-icon:after {
                    background: #4d9fde;
                }
                ul li:hover .contact-facebook-icon{
                    background: #3b5999;
                }
        
                ul li:hover .contact-facebook-icon:before {
                    background: #2e4a86;
                }
                ul li:hover .contact-facebook-icon:after {
                    background: #4a69ad;
                }
               
                ul li:hover .contact-telegram-icon {
                    background: #34ADE1;
                }
        
                ul li:hover .contact-telegram-icon:before {
                    background: rgb(47, 156, 202);
                }
                ul li:hover .contact-telegram-icon:after {
                    background: rgb(41, 139, 180);
                }
        
                ul li:hover .contact-medium-icon {
                    background: rgb(29, 29, 29);
                }
        
                ul li:hover .contact-medium-icon:before {
                    background: rgb(31, 31, 31);
                }
                ul li:hover .contact-medium-icon:after {
                    background: black;
                }
        
               
        
                ul li:hover:nth-child(5) a {
                    background: linear-gradient(#400080, transparent),
                        linear-gradient(200deg, #d047d1, #ff0000, #ffff00);
                }
        
                ul li:hover:nth-child(5) a:before {
                    background: linear-gradient(#400080, transparent),
                        linear-gradient(200deg, #d047d1, #ff0000, #ffff00);
                }
                ul li:hover:nth-child(5) a:after {
                    background: linear-gradient(#400080, transparent),
                        linear-gradient(200deg, #d047d1, #ff0000, #ffff00);
                }
                ul li .contact-iotex-icon,ul li .contact-iotex-icon-hover{
                    position: absolute;
                    margin: auto;
                    top: 0px;
                    bottom: 0px;
                    left: 0px;
                    right: 0px;
                }
                
                ul li:hover .contact-iotex-icon{
                    display:none;
                }
                ul li:hover .contact-iotex-icon-hover{
                    display:block;
                }
            }
            
        }
    }
}

/*** 

Countdown Library 

****/
.flipdown.flipdown__theme-dark .rotor-group-heading:before {
    color: black;
}
.flipdown.flipdown__theme-dark
    .rotor-group:nth-child(n + 2):nth-child(-n + 3):before,
.flipdown.flipdown__theme-dark
    .rotor-group:nth-child(n + 2):nth-child(-n + 3):after {
    background-color: black;
}

.flipdown.flipdown__theme-dark {
    font-family: sans-serif;
    font-weight: bold;
}


.flipdown.flipdown__theme-dark .rotor,
.flipdown.flipdown__theme-dark .rotor-top,
.flipdown.flipdown__theme-dark .rotor-leaf-front {
    color: #fff;
    background-color: #151515;
}
.flipdown.flipdown__theme-dark .rotor-bottom,
.flipdown.flipdown__theme-dark .rotor-leaf-rear {
    color: #efefef;
    background-color: #202020;
}
.flipdown.flipdown__theme-dark .rotor:after {
    border-top: solid 1px #151515;
}
.flipdown.flipdown__theme-light {
    font-family: sans-serif;
    font-weight: bold;
}
.flipdown.flipdown__theme-light .rotor-group-heading:before {
    color: #eee;
}
.flipdown.flipdown__theme-light
    .rotor-group:nth-child(n + 2):nth-child(-n + 3):before,
.flipdown.flipdown__theme-light
    .rotor-group:nth-child(n + 2):nth-child(-n + 3):after {
    background-color: #ddd;
}
.flipdown.flipdown__theme-light .rotor,
.flipdown.flipdown__theme-light .rotor-top,
.flipdown.flipdown__theme-light .rotor-leaf-front {
    color: #222;
    background-color: #ddd;
}
.flipdown.flipdown__theme-light .rotor-bottom,
.flipdown.flipdown__theme-light .rotor-leaf-rear {
    color: #333;
    background-color: #eee;
}
.flipdown.flipdown__theme-light .rotor:after {
    border-top: solid 1px #222;
}
.flipdown {
    overflow: visible;
    width: 510px;
    height: 110px;
}
.flipdown .rotor-group {
    position: relative;
    float: left;
    padding-right: 30px;
}
.flipdown .rotor-group:last-child {
    padding-right: 0;
}
.flipdown .rotor-group-heading:before {
    display: block;
    height: 30px;
    line-height: 30px;
    text-align: center;
}
.flipdown .rotor-group:nth-child(1) .rotor-group-heading:before {
    content: "Days";
}
.flipdown .rotor-group:nth-child(2) .rotor-group-heading:before {
    content: "Hours";
}
.flipdown .rotor-group:nth-child(3) .rotor-group-heading:before {
    content: "Minutes";
}
.flipdown .rotor-group:nth-child(4) .rotor-group-heading:before {
    content: "Seconds";
}
.flipdown .rotor-group:nth-child(n + 2):nth-child(-n + 3):before {
    content: "";
    position: absolute;
    bottom: 20px;
    left: 115px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
}
.flipdown .rotor-group:nth-child(n + 2):nth-child(-n + 3):after {
    content: "";
    position: absolute;
    bottom: 50px;
    left: 115px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
}
.flipdown .rotor {
    position: relative;
    float: left;
    width: 50px;
    height: 80px;
    margin: 0 5px 0 0;
    border-radius: 4px;
    font-size: 4rem;
    text-align: center;
    perspective: 200px;
}
.flipdown .rotor:last-child {
    margin-right: 0;
}
.flipdown .rotor-top,
.flipdown .rotor-bottom {
    overflow: hidden;
    position: absolute;
    width: 50px;
    height: 40px;
}
.flipdown .rotor-leaf {
    z-index: 1;
    position: absolute;
    width: 50px;
    height: 80px;
    transform-style: preserve-3d;
    transition: transform 0s;
}
.flipdown .rotor-leaf.flipped {
    transform: rotateX(-180deg);
    transition: all 0.5s ease-in-out;
}
.flipdown .rotor-leaf-front,
.flipdown .rotor-leaf-rear {
    overflow: hidden;
    position: absolute;
    width: 50px;
    height: 40px;
    margin: 0;
    transform: rotateX(0);
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
}
.flipdown .rotor-leaf-front {
    line-height: 80px;
    border-radius: 4px 4px 0 0;
}
.flipdown .rotor-leaf-rear {
    line-height: 0;
    border-radius: 0 0 4px 4px;
    transform: rotateX(-180deg);
}
.flipdown .rotor-top {
    line-height: 80px;
    border-radius: 4px 4px 0 0;
}
.flipdown .rotor-bottom {
    bottom: 0;
    line-height: 0;
    border-radius: 0 0 4px 4px;
}
.flipdown .rotor:after {
    content: "";
    z-index: 2;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 50px;
    height: 40px;
    border-radius: 0 0 4px 4px;
}
@media (max-width: 550px) {
    .flipdown {
        width: 312px;
        height: 70px;
    }
    .flipdown .rotor {
        font-size: 2.2rem;
        margin-right: 3px;
    }
    .flipdown .rotor,
    .flipdown .rotor-leaf,
    .flipdown .rotor-leaf-front,
    .flipdown .rotor-leaf-rear,
    .flipdown .rotor-top,
    .flipdown .rotor-bottom,
    .flipdown .rotor:after {
        width: 30px;
    }
    .flipdown .rotor-group {
        padding-right: 20px;
    }
    .flipdown .rotor-group:last-child {
        padding-right: 0;
    }
    .flipdown .rotor-group-heading:before {
        font-size: 0.8rem;
        height: 20px;
        line-height: 20px;
    }
    .flipdown .rotor-group:nth-child(n + 2):nth-child(-n + 3):before,
    .flipdown .rotor-group:nth-child(n + 2):nth-child(-n + 3):after {
        left: 69px;
    }
    .flipdown .rotor-group:nth-child(n + 2):nth-child(-n + 3):before {
        bottom: 13px;
        height: 8px;
        width: 8px;
    }
    .flipdown .rotor-group:nth-child(n + 2):nth-child(-n + 3):after {
        bottom: 29px;
        height: 8px;
        width: 8px;
    }
    .flipdown .rotor-leaf-front,
    .flipdown .rotor-top {
        line-height: 50px;
    }
    .flipdown .rotor-leaf,
    .flipdown .rotor {
        height: 50px;
    }
    .flipdown .rotor-leaf-front,
    .flipdown .rotor-leaf-rear,
    .flipdown .rotor-top,
    .flipdown .rotor-bottom,
    .flipdown .rotor:after {
        height: 25px;
    }
}
