html,
body {
    background: $core-brand-background-color;
    color: $core-brand-text-color;
}
.clearfix:before,
.clearfix:after {
    content: "";
    display: table;
}
.section {
    //margin-top:80px;
    //display: flex;
    /* offset for first section */
    .first-section {
        margin-top: 77px;
        .socialMedia-connect {
            .socialMedia-heading {
                margin-top: 100px;
                margin-bottom: 0px;
                font-size: 22px;
            }
        }
    }
    /* center headline */
    .centerblock {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-align-items: center;
        align-items: center;
        .floatParagraph{
            width: 60vw;
            .document-body {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                padding: 3rem;
                height: 60vh;
                .document-header {
                    font-family: "ShareTech";
                    font-size: 2.5rem;
                }
                .document-content {
                    flex-direction: column;
                    justify-content: flex-end;
                    font-size: 1rem;
                    line-height: 1.5;
                }
            }
        }
    }
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    .thirdExtra {
        height: 133vh;
    }
    .quarterExtra {
        height: 125vh;
    }
    .halfExtra {
        height: 150vh;
    }
    .section-content-image {
        img {
            width: 48vw;
            height: 100vh;
            background-size: cover !important;
            float: right;
            background-repeat: no-repeat;
            transition: all 0.5s ease;
            background-position: center center;
        }
        &.full {
            img {
                width: 100vw;
                height: 90vh;
                margin-bottom: 100px;
            }
        }
        &.left {
            float: right;
        }
        &.right {
            float: left;
        }
    }
    .section-content-text {
        float: right;
        width: 52vw;
        height: 100vh;
        vertical-align: top;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background-color: $core-brand-background-color;
        &.right {
            float: right;
        }
        &.left {
            float: left;
        }
        .responsive-section-content-image {
            display: none;
        }
        .document-body {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            padding: 3rem;
            height: 60vh;
            .document-header {
                font-family: "ShareTech";
                font-size: 2.5rem;
            }
            .document-content {
                flex-direction: column;
                justify-content: flex-end;
                font-size: 1rem;
                line-height: 1.5;
            }
        }
    }
}
