.dc-page {
    background-color: #f7f7f7;
    .container {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
}

.search-page {
    padding: 80px 30px 0px 30px;
    min-height: 100%;
    .access-column {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .accessText {
        }
        .accessImg {
            img {
                width: 16px;
                height: 16px;
                margin-right: 3px;
            }
        }
    }
}
