.sec-intro {
    background-image: url("../images/new/Introduction.png");
    .intro-group {
        .websiteTitle {
            height: 500px;
            width: 50vw;
        }

        .websiteSlogan {
            font-family: "ShareTech";
            line-height: 1.5;
            font-size: 2.6rem;
        }


    }
    .resources-container {
        width: 50%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        .fa-icon-btn {
            display: flex;
            justify-content: center;
            .voterhandbookIcon {
                font-size: 70px;
                color: #758293;
                //margin: 15px 0px;
            }
            .voterhandbookIcon:hover {
                cursor: pointer;
                color: rgb(121, 223, 177);
            }
        }
    }
    .englishcontent,
    .koreancontent {
        margin: 50px 0px;
    }
    .mainnet-title-main {
        font-size: 2.5rem;
        margin-bottom: 1.5rem;
        text-align: center;
    }
    .mainnet-title-sub {
        font-size: 1.2rem;
        margin-bottom: 1.5rem;
        text-align: center;
    }
    .mainnet-twitter-text {
        font-size: 0.9rem;
        margin-top: 1.5rem;
        text-align: center;
        i {
            margin-right: 0.5rem;
        }
    }
    .socialMedia-connect {
        .socialMedia-heading {
            font-family: "ShareTech";
            margin-bottom: 55px;
            font-size: 30px;
            display: flex;
            justify-content: center;
        }
        .socialMedia-content {
            ul {
                display: flex;
                justify-content: center;
                padding-inline-start: 0px;
                margin-block-start: 2em;
                margin-block-end: 2em;
                /*
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                margin: 0;
                padding: 0;
                display: flex;
                */
            }
            ul li {
                list-style: none;
                margin: 0 40px;
            }
            ul li .fa {
                font-size: 20px;
                color: #262626;
                line-height: 40px;
                transition: 0.5s;
            }
            ul li a {
                position: relative;
                display: block;
                width: 40px;
                height: 40px;
                background-color: #758293;
                text-align: center;
                transform: perspective(100px) rotate(-30deg) skew(25deg)
                    translate(0, 0);
                transition: 0.5s;
                box-shadow: -20px 20px 1px 1px black;
            }
            ul li a::before {
                content: "";
                position: absolute;
                top: 10px;
                left: -20px;
                height: 100%;
                width: 20px;
                background: #5a636f;
                transition: 0.5s;
                transform: rotate(0deg) skewY(-45deg);
            }
            ul li a::after {
                content: "";
                position: absolute;
                top: 40px;
                left: -11px;
                height: 20px;
                width: 100%;
                background: #5a636f;
                transition: 0.5s;
                transform: rotate(0deg) skewX(-45deg);
            }
            ul li a:hover {
                transform: perspective(1000px) rotate(-30deg) skew(25deg)
                    translate(15px, -15px);
                box-shadow: -40px 40px 40px 20px black;
            }
            ul li:hover .fa {
                color: #fff;
            }

            ul li:hover .contact-iotex-voting-icon {
                background: #71d1a5;
            }

            ul li:hover .contact-iotex-voting-icon:before {
                background: rgb(121, 223, 177);
            }
            ul li:hover .contact-iotex-voting-icon:after {
                background: rgb(105, 194, 154);
            }
            ul li:hover .contact-twitter-icon {
                background: #55acee;
            }
            ul li:hover .contact-twitter-icon:before {
                background: #4184b7;
            }
            ul li:hover .contact-twitter-icon:after {
                background: #4d9fde;
            }
            ul li:hover .contact-facebook-icon {
                background: #3b5999;
            }

            ul li:hover .contact-facebook-icon:before {
                background: #2e4a86;
            }
            ul li:hover .contact-facebook-icon:after {
                background: #4a69ad;
            }

            ul li:hover .contact-telegram-icon {
                background: #34ade1;
            }

            ul li:hover .contact-telegram-icon:before {
                background: rgb(47, 156, 202);
            }
            ul li:hover .contact-telegram-icon:after {
                background: rgb(41, 139, 180);
            }

            ul li:hover .contact-medium-icon {
                background: rgb(29, 29, 29);
            }

            ul li:hover .contact-medium-icon:before {
                background: rgb(31, 31, 31);
            }
            ul li:hover .contact-medium-icon:after {
                background: black;
            }

            ul li:hover:nth-child(5) a {
                background: linear-gradient(#400080, transparent),
                    linear-gradient(200deg, #d047d1, #ff0000, #ffff00);
            }

            ul li:hover:nth-child(5) a:before {
                background: linear-gradient(#400080, transparent),
                    linear-gradient(200deg, #d047d1, #ff0000, #ffff00);
            }
            ul li:hover:nth-child(5) a:after {
                background: linear-gradient(#400080, transparent),
                    linear-gradient(200deg, #d047d1, #ff0000, #ffff00);
            }
            ul li .contact-iotex-icon,
            ul li .contact-iotex-icon-hover {
                position: absolute;
                margin: auto;
                top: 0px;
                bottom: 0px;
                left: 0px;
                right: 0px;
            }

            ul li:hover .contact-iotex-icon {
                display: none;
            }
            ul li:hover .contact-iotex-icon-hover {
                display: block;
            }
        }
    }
}
