.section {
    .clearfix:not(.first-section):not(.last-section) {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        .section-content-image {
            &.left,
            &.right {
                img {
                    float: none;
                    width: 100vw;
                    //position: absolute;
                }
            }
        }

        .section-content-text {
            &.left,
            &.right {
                float: none;
                width: 100vw;
                //position: absolute;
            }
        }
    }
    .first-section {
    }

    .websiteSlogan {
        padding: 0px 4rem;
    }

    .socialMedia-connect {
        .socialMedia-heading {
            margin-top: 50px;
            margin-bottom: 0px;
            font-size: 22px;
        }
    }

    .last-section {
        width: 100vw;
        position: absolute;
    }
}

.contact-section {
    display: flex;
    flex-direction: column !important;
    justify-content: space-between;
    width: 100vw;
}

.sec-intro .intro-group .websiteTitle {
    width: 90vw !important;
}
