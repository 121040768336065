.cyan-cp-text {
    color: #26dafd;
    bottom: 0;
    display: block;
    opacity: 1;
    overflow: hidden;
    box-sizing: border-box;
    transition: all 250ms ease-out;
    line-height: 1.5;
    font-family: "Titillium Web", "sans-serif";
}
