/***** Libaray CSS override for ScrollUpButton *****
************************************************/
.AnyClassForContainer {
    position: fixed;
    right: -100px;
    bottom: 20px;
    -webkit-transition: right 0.5s;
    transition: right 0.5s;
    cursor: pointer;
    background-color: #76b900;
    color: white;
    font-size: 15px;
    padding: 8px;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    outline: none;
}

.AnyClassForTransition {
    right: 20px;
    z-index: 2;
}

/***** Library CSS Override for Fullpage.js Dot Navigation****
**************************************************************/
#fp-nav ul li,
.fp-slidesNav ul li {
    margin-bottom: 40px !important;
    visibility: inherit;
    opacity: 1 !important;
}
#fp-nav ul li::after {
    content: "";
    position: absolute;
    width: 1px;
    height: 50px;
    background-color: white;
    display: block;
    left: 7px;
    top: 12px;
    opacity: 0.1;
}
#fp-nav ul li:last-child::after {
    display: none;
}

#fp-nav ul li .fp-tooltip {
    width: auto !important;
    opacity: 1 !important;
    color: black !important;
    cursor: cursor !important;
    margin-left: 5px;
    text-transform: uppercase;
    font-size: 11px;
    line-height: 1.5;
}
#fp-nav.fp-show-active a.active + .fp-tooltip {
    color: #1E90FF!important;
}

#fp-nav ul li a span,
.fp-slidesNav ul li a span {
    width: 10px !important;
    height: 10px !important;
    margin: -4px 0 0 -4px !important;
    background: transparent;
    box-sizing: border-box;
    box-shadow: inset 0 0 0 2px white;
    overflow: hidden;

    &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 0;
        border-radius: 50%;
        left: 0;
        bottom: 0;
        background-color: #1E90FF;
        box-shadow: 0 0 1px #1E90FF;
        transition: height 0.3s;
    }
}

#fp-nav ul li a.active span,
.fp-slidesNav ul li a.active span,
#fp-nav ul li:hover a.active span,
.fp-slidesNav ul li:hover a.active span {
    width: 16px;
    height: 16px;
    margin: -8px 0 0 -8px;
    background: transparent;
    box-sizing: border-box;
    box-shadow: inset 0 0 0 2px white;

    &::after {
        height: 100%;
    }
}
